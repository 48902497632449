.footerSubheader {
    color: #1F81C5; 
    text-decoration: underline;
    font-weight: bold;
    font-size: 25px !important;
}

.footerCategory {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px !important;

}