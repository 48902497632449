.MuiTabs-indicator {
    background-color: #EADBC9 !important;
    width: 0 !important;
}

.eventDetailTab .MuiTabs-fixed .MuiTabs-flexContainer .Mui-selected{
    /* background-color: #EADBC9 !important; */
    background: rgb(249,161,30);
    background: linear-gradient(90deg, rgba(249,161,30,1) 0%, rgba(251,178,69,1) 35%, rgba(255,113,7,1) 100%);
    font-weight: bold;
    font-size: 21px;
    color: #FFFFFF;
}

.dangerouslyInnerEventDetail img{
        /* width: calc(100% + 48px) !important; */
        /* margin-left: -24px; */
        width: 100% !important;
        max-width: 100%;
}
.MuiDialog-paperFullScreen::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.tabPanelInEventDetail{
    -webkit-box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
-moz-box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
margin-top: 1px !important;
background-color: #FFFFFF;

}