.MuiTypography-h2{
    font-size: 2.5em !important
}

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input{
    text-align: center;
}

.AdvanceSearchToggle .Mui-selected{
    background-color: #FB8D0A !important;
}

.e-bigger .e-daterangepicker .e-start-btn.e-active, .e-bigger .e-daterangepicker .e-start-btn.e-active:active, .e-bigger .e-daterangepicker .e-end-btn.e-active, .e-bigger .e-daterangepicker .e-end-btn.e-active:active:not([disabled]), .e-bigger .e-daterangepicker .e-start-btn.e-active:hover, .e-bigger .e-daterangepicker .e-end-btn.e-active:hover, .e-bigger.e-daterangepicker .e-start-btn.e-active, .e-bigger.e-daterangepicker .e-start-btn.e-active:active, .e-bigger.e-daterangepicker .e-end-btn.e-active, .e-bigger.e-daterangepicker .e-end-btn.e-active:active:not([disabled]), .e-bigger.e-daterangepicker .e-start-btn.e-active:hover, .e-bigger.e-daterangepicker .e-end-btn.e-active:hover, .e-device.e-daterangepicker .e-start-btn.e-active, .e-device.e-daterangepicker .e-start-btn.e-active:active, .e-device.e-daterangepicker .e-end-btn.e-active, .e-device.e-daterangepicker .e-end-btn.e-active:active:not([disabled]), .e-device.e-daterangepicker .e-start-btn.e-active:hover, .e-device.e-daterangepicker .e-end-btn.e-active:hover{
    background-color: #FB8D0A !important;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-calendar .e-content.e-decade td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day{
    background-color: #FB8D0A !important;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day, .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
    background-color: #FB8D0A !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
    background-color: #FB8D0A !important;
}

.ModalDialog{
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
}

.datePickerInModalPC .e-input-group{
    border-bottom: 0px !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after, .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after{
    width: 0%;
}

/* .e-daterangepicker.e-popup, .e-bigger.e-small .e-daterangepicker.e-popup {
    position: static;
} */