.inputBoxInAdvanceSearchModal, .datePickerInSearchModal .e-input-group {

    -webkit-box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
-moz-box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
box-shadow: -1px 2px 5px 1px rgba(224,220,224,1);
border: 1px solid grey !important;
border-radius: 5px;
}


body ::-webkit-scrollbar{
    width: 0px !important;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left){
    border-bottom: 0px !important;
}